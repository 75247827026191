import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./Login.js";
import Home from "./Home/Home.js";

function App() {
  return (
    <div className="App">
      <Routes>
        {localStorage.getItem("user") === "1" ? (
          <Route path="/" element={<Home />} />
        ) : (
          <Route path="/" element={<Login />} />
        )}
      </Routes>
    </div>
  );
}

export default App;
