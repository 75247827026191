import React, { useState } from "react";
import "./App.css";
import Logo from "./Images/logo.png";

function Login(props) {
  const [data, setData] = useState({
    login: "",
    passw: "",
  });

  const onChangeHandler = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };

  // const [errP, setErrP] = useState("");
  // const [errPs, setErrPs] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    const url = new URL("https://api.kebyo.uz/api/login/moderator");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let body = {
      phone: data.login,
      password: data.passw,
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => handleLogin(result))
      .catch((error) => {
        window.location.reload(false);
        console.log(error);
      });
    setData({ login: "", passw: "" });
  }

  function handleLogin(result) {
    localStorage.setItem("branch_id", result.result.branch.id);
    localStorage.setItem("token", result.result.token);
    localStorage.setItem("user", 1);
    window.location.reload(false);
  }
  return (
    <div className="LoginPage">
      <span className="circle1"></span>
      <span className="circle2"></span>
      <span className="circle3"></span>
      <span className="circle4"></span>
      <span className="circle5"></span>
      <span className="circle6"></span>
      <div className="card">
        <img src={Logo} alt="" />
        <form action="" onSubmit={handleSubmit}>
          <label htmlFor="login">
            <p>Login</p>
            <input
              id="login"
              type="text"
              value={data.login}
              onChange={onChangeHandler}
              name="login"
            />
            {/* <p className="error">{errP}</p> */}
          </label>
          <label htmlFor="pass">
            <p>Parol</p>
            <input
              id="pass"
              type="password"
              value={data.passw}
              onChange={onChangeHandler}
              name="passw"
            />
            {/* <p className="error">{errPs}</p> */}
          </label>
          <button type="submit" className="send_button">
            Kirish
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
