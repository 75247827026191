import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Timer from "./Timer";
import { IoIosNotificationsOutline } from "react-icons/io";
import { RiDeleteBin6Line, RiLogoutBoxLine } from "react-icons/ri";
import notiiiif from "../Sound/1.mp3";

function Home(props) {
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [id, setId] = useState(0);

  // const [end_timer, setEndTimer] = useState("");

  function OpenM(idd) {
    // startTimer(index);
    // setTime(end_time);
    // localStorage.setItem(
    //   `timerState_${index}`,
    //   JSON.stringify({ is_busy: 1, startTime: new Date().getTime() })
    // );
    // setEndTimer(end_time);
    setModal(true);
    setId(idd);
  }
  // console.log(pul);

  const [val_id2, setValId2] = useState();
  const [val_id3, setValId3] = useState();

  function OpenM2(id) {
    setModal2(true);
    // console.log(id);
    setValId2(id);
  }
  function OpenM3(id) {
    setModal3(true);
    // console.log(id);
    setValId3(id);
  }
  function CloseM(params) {
    setModal(false);
  }

  function CloseM2(params) {
    setModal2(false);
  }

  // function VipQilish(params) {
  //   setModal(false);
  // }
  // function VipQilish2(params) {
  //   setModal2(false);
  // }

  function StopComp(params) {
    const url = new URL("https://api.kebyo.uz/api/computer/rollback");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    let body = {
      id: params,
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => window.location.reload(false));
  }

  // const [isChecked, setIsChecked] = useState(false);
  // const checkboxRef = useRef();/

  const onChangeHandler = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };

  // const checkHandler = () => {
  //   setIsChecked(!isChecked);
  // };

  const [isChecked, setIsChecked] = useState(false);

  const [data, setData] = useState({
    sum: 0,
  });

  function Send(params) {
    params.preventDefault();
    const url = new URL("https://api.kebyo.uz/api/computer/update");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    let body = {
      id: id,
      is_vip: isChecked,
      amount: data.sum,
    };

    fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        window.location.reload(false);
      });

    setData({
      sum: 0,
    });
  }
  // console.log(localStorage.getItem("qolgan_pul"));
  const [data2, setData2] = useState({
    sum: 0,
    pc: 0,
  });

  const onChangeHandler2 = (e) => {
    const newdata = { ...data2 };
    newdata[e.target.name] = e.target.value;
    setData2(newdata);
  };
  const [selectedP, setSelectedP] = useState(0);

  function Send2(params) {
    params.preventDefault();
    params.preventDefault();
    const url = new URL("https://api.kebyo.uz/api/computer/update");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    let body = {
      id: val_id2,
      id2: selectedP,
      is_vip: isChecked,
      amount: data2.sum,
    };

    fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        window.location.reload(false);
      });
    setData2({
      sum: 0,
    });
  }
  function Send3(params) {
    params.preventDefault();
    params.preventDefault();
    const url = new URL("https://api.kebyo.uz/api/computer/update");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    let body = {
      id: val_id3,
      id2: selectedP,
      is_vip: isChecked,
      amount: data2.sum,
    };

    fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        window.location.reload(false);
      });
    setData2({
      sum: 0,
    });
  }

  // ===============================

  const [comp, setComp] = useState({
    result: [
      {
        id: 1,
        name: "PC",
        is_vip: 0,
        is_busy: 0,
        start_time: null,
        end_time: null,
        branch_id: 1,
        tariff_id: 1,
        type: 0,
        ignore1: 0,
        ignore2: 0,
        created_at: "2023-09-16T22:04:36.000000Z",
        updated_at: "0000-00-00T00:00:00.000000Z",
        tariff: {
          id: 1,
          name: "vip",
          price: 7000,
          branch_id: 1,
          type: 1,
          created_at: "2023-09-16T22:02:58.000000Z",
          updated_at: "2023-09-16T22:03:33.000000Z",
        },
      },
    ],
  });

  // const stopTimer = (id) => {
  //   setTimerStates((prevTimerStates) => {
  //     return prevTimerStates.map((timerState, index) => {
  //       if (index === id && timerState.running) {
  //         const currentTime = new Date().getTime();
  //         const elapsedTime = currentTime - timerState.startTime;
  //         localStorage.setItem(
  //           `timerState_${id}`,
  //           JSON.stringify({ running: false, elapsedTime })
  //         );
  //         return { ...timerState, running: false, elapsedTime };
  //       } else {
  //         return timerState;
  //       }
  //     });
  //   });
  // };

  const [ps, setPs] = useState({
    result: [
      {
        id: 1,
        name: "Ps",
        is_vip: 0,
        is_busy: 0,
        end_time: null,
        start_time: null,
        branch_id: 1,
        tariff_id: 1,
        type: 0,
        ignore1: 0,
        ignore2: 0,
        created_at: "2023-09-16T22:04:36.000000Z",
        updated_at: "2023-09-16T22:14:35.000000Z",
        tariff: {
          id: 1,
          name: "vip",
          price: 7000,
          branch_id: 1,
          type: 1,
          created_at: "2023-09-16T22:02:58.000000Z",
          updated_at: "2023-09-16T22:03:33.000000Z",
        },
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      const url = new URL("https://api.kebyo.uz/api/get-computers-by-filter");

      const params = {
        branch_id: localStorage.getItem("branch_id"),
        type: "1",
        // Yana parametrlar qo'shishingiz mumkin
      };

      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      try {
        const response = await fetch(url, {
          method: "GET",
          headers,
        });

        if (!response.ok) {
          throw new Error("Ma'lumotlarni olishda xato yuz berdi");
        }

        const res = await response.json();
        setPs(res);
      } catch (error) {
        console.error("Ma'lumotlarni olishda xato yuz berdi:", error);
      }
    };

    fetchData();
    // Har bir daqiqada fetchData funktsiyasini avtomatik ravishda chaqirish uchun setInterval qo'shildi
    // const intervalId = setInterval(window.location.reload(true), 60000);

    // useEffect chiqarilganda intervalni o'chirish
    // return () => clearInterval(intervalId);
  }, []);

  setInterval(() => {
    window.location.reload(true);
  }, 60000);

  useEffect(() => {
    const url2 = new URL("https://api.kebyo.uz/api/get-computers-by-filter");

    const params2 = {
      branch_id: localStorage.getItem("branch_id"),
      type: "0",
    };
    Object.keys(params2).forEach((key) =>
      url2.searchParams.append(key, params2[key])
    );

    const headers2 = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    // let body = {
    //   branch_id: 18,
    //   tariff_id: 12,
    //   type: true,
    // };

    fetch(url2, {
      method: "GET",
      headers2,
      // body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => setComp(res));
  }, []);
  // console.log(end_timer);

  // const [timers, setTimers] = useState();

  // useEffect(() => {
  //   const newTimers = {};

  //   comp.result.forEach((val) => {
  //     if (val.is_busy === 1 && val.end_time) {
  //       const timerId = setInterval(() => {
  //         newTimers[val.id] = newTimers[val.id] || {};
  //         newTimers[val.id].value = val.end_time - new Date().getTime();
  //         setTimers({ ...newTimers });
  //       }, 1000);
  //     }
  //   });

  //   return () => {
  //     Object.values(newTimers).forEach((timer) => {
  //       if (timer.value) clearInterval(timer.value);
  //     });
  //   };
  // }, [comp.result]);

  const [notif, setNotif] = useState(false);
  const [notifData, setNotifData] = useState([
    {
      id: 0,
      user_id: 0,
      first_name: "",
      computers_id: "",
      branch_id: 0,
      created_at: "",
      updated_at: "",
      deleted_at: null,
    },
  ]);
  const [notifCount, setNotifCount] = useState(0);

  function openNotif(params) {
    setNotif(true);
  }
  function closeNotif(params) {
    setNotif(false);
  }
  function logOut(params) {
    localStorage.clear();
    window.location.reload(false);
  }

  useEffect(() => {
    const url = new URL(
      `https://api.kebyo.uz/api/order/notifications/${localStorage.getItem(
        "branch_id"
      )}`
    );

    const params = {
      branch_id: localStorage.getItem("branch_id"),
    };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => {
        setNotifData(res.result);
        setNotifCount(res.result.length);
      });
  }, []);

  let i = 1;

  function deleteNot(paramss) {
    const url = new URL(`https://api.kebyo.uz/api/order/delete/${paramss}`);

    const params = {
      id: paramss,
    };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "DELETE",
      headers,
    })
      .then((response) => response.json())
      .then((res) => window.location.reload(false));
  }

  return (
    <div className="table">
      <div className={modal ? "modal active" : "modal"}>
        <div className="centered_modal">
          <span className="closeModal" onClick={CloseM}>
            <IoClose />
          </span>
          <form action="" onSubmit={Send}>
            <div className="title">
              <p>Pul Miqdorini kiriting:</p>
            </div>
            <div className="vip_sum">
              <div className="but_check">
                <label>
                  <input
                    type="checkbox"
                    className="chekboks"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  <p className="VIP">VIP qilish</p>
                </label>
              </div>
              <input
                type="number"
                name="sum"
                onChange={onChangeHandler}
                value={data.sum}
                className="summa"
              />
            </div>
            <div className="submit_button">
              <button type="submit">Saqlash</button>
            </div>
          </form>
        </div>
      </div>
      <div className={modal3 ? "modal2 active" : "modal2"}>
        <div className="centered_modal2">
          <span className="closeModal" onClick={CloseM2}>
            <IoClose />
          </span>
          <form action="" onSubmit={Send3}>
            <div className="title">
              <p>Pul Miqdorini kiriting:</p>
            </div>
            <div className="vip_sum">
              <div className="but_check">
                <label>
                  <input
                    type="checkbox"
                    className="chekboks"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  <p className="VIP">VIP qilish</p>
                </label>
              </div>
              <input
                type="number"
                name="sum"
                onChange={onChangeHandler2}
                value={data2.sum}
                className="summa"
              />
            </div>
            <div className="title2">
              <p>Vaqtni ko'chirish uchun Playstationni tanlang:</p>
            </div>
            <div className="select">
              <select
                name="pc"
                onChange={(e) => setSelectedP(e.target.value)}
                value={selectedP}
              >
                <option value="nol">Playstationni tanlang</option>
                {ps.result
                  .filter((val) => val.is_busy === 0)
                  .map((val) => (
                    <>
                      <option key={val.id} value={val.id}>
                        {val.name}
                      </option>
                    </>
                  ))}
              </select>
            </div>
            <div className="toxtatish">
              <div className="submit_button">
                <button type="submit">Saqlash</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={modal2 ? "modal2 active" : "modal2"}>
        <div className="centered_modal2">
          <span className="closeModal" onClick={CloseM2}>
            <IoClose />
          </span>
          <form action="" onSubmit={Send2}>
            <div className="title">
              <p>Pul Miqdorini kiriting:</p>
            </div>
            <div className="vip_sum">
              <div className="but_check">
                <label>
                  <input
                    type="checkbox"
                    className="chekboks"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  <p className="VIP">VIP qilish</p>
                </label>
              </div>
              <input
                type="number"
                name="sum"
                onChange={onChangeHandler2}
                value={data2.sum}
                className="summa"
              />
            </div>
            <div className="title2">
              <p>Vaqtni ko'chirish uchun kompyuterni tanlang:</p>
            </div>
            <div className="select">
              <select
                name="pc"
                onChange={(e) => setSelectedP(e.target.value)}
                value={selectedP}
              >
                <option value="nol">Kompyuterni tanlang</option>
                {comp.result
                  .filter((val) => val.is_busy === 0)
                  .map((val) => (
                    <>
                      <option key={val.id} value={val.id}>
                        {val.name}
                      </option>
                    </>
                  ))}
              </select>
            </div>
            <div className="toxtatish">
              <div className="submit_button">
                <button type="submit">Saqlash</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <table className="table1">
        <thead>
          <tr>
            <th>Kompyuterlar</th>
            <th>Tarif</th>
            <th>Narx</th>
            <th>Qolgan pul</th>
            <th>Qolgan vaqt</th>
            <th>Vaqt qo'yish / uzaytirish</th>
            <th>Vaqt toxtatish</th>
          </tr>
        </thead>
        <tbody>
          {comp.result.map((val, index) => {
            // const timerState = timerStates[index];
            // const isTimerRunning = timerState.is_busy;
            // const elapsedTime = timerState.elapsedTime || 0;
            // const timerValue = timers[val.id] ? timers[val.id].value : 0;
            return (
              <tr
                key={val.id}
                className={val.is_busy === 0 ? "yashil" : "qizil"}
              >
                <td>{val.name}</td>
                <td>{val.tariff.name}</td>
                <td>{val.tariff.price}</td>
                {val.end_time === null ? (
                  <>
                    <td className="salom">
                      <p>0 UZS</p>
                    </td>
                    <td className="salom">
                      <p>00:00</p>
                    </td>
                  </>
                ) : (
                  <Timer
                    isvip={val.is_vip}
                    start_time={val.start_time}
                    tarif_narxi={val.tariff.price}
                    end_time={val.end_time}
                  />
                )}
                {val.is_busy === 0 ? (
                  <td className="vaqtlar">
                    <button
                      className="add_time"
                      onClick={() => {
                        OpenM(val.id);
                      }}
                    >
                      VAQT QO'YISH
                    </button>
                  </td>
                ) : (
                  <>
                    <td>
                      <button
                        className="add_time"
                        onClick={() => OpenM2(val.id)}
                        // onClick={() => console.log(val.id)}
                      >
                        UZAYTIRISH
                      </button>
                    </td>
                  </>
                )}
                <td className="toxtatish">
                  <button
                    className="add_time"
                    disabled={val.is_busy === 1 ? false : true}
                    onClick={() => StopComp(val.id)}
                  >
                    Vaqt to'xtatish
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <table className="table2">
        <thead>
          <tr>
            <th>Playstationlar</th>
            <th>Tarif</th>
            <th>Narx</th>
            <th>Qolgan vaqt</th>
            <th>Qolgan pul</th>
            <th>Vaqt qo'yish / uzaytirish</th>
            <th>Vaqt toxtatish</th>
          </tr>
        </thead>
        <tbody>
          {ps.result.map((val) => (
            <tr key={val.id} className={val.is_busy === 0 ? "kok" : "qizil"}>
              <td>{val.name}</td>
              <td>{val.tariff.name}</td>
              <td>{val.tariff.price}</td>
              {val.end_time === null ? (
                <>
                  <td className="salom">
                    <p>0 UZS</p>
                  </td>
                  <td className="salom">
                    <p>00:00</p>
                  </td>
                </>
              ) : (
                <Timer
                  isvip={val.is_vip}
                  start_time={val.start_time}
                  tarif_narxi={val.tariff.price}
                  end_time={val.end_time}
                />
              )}
              {val.is_busy === 0 ? (
                <td className="vaqtlar">
                  <button
                    className="add_time"
                    onClick={() => {
                      OpenM(val.id);
                    }}
                  >
                    VAQT QO'YISH
                  </button>
                </td>
              ) : (
                <>
                  <td className="vaqtlar">
                    <button
                      className="add_time"
                      onClick={() => OpenM3(val.id)}
                      // onClick={() => console.log(val.id)}
                    >
                      UZAYTIRISH
                    </button>
                  </td>
                </>
              )}
              <td className="toxtatish">
                <button
                  className="add_time"
                  disabled={val.is_busy === 1 ? false : true}
                  onClick={() => StopComp(val.id)}
                >
                  Vaqt to'xtatish
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="notif" onClick={openNotif}>
        {notifCount === 0 ? "" : <span>{notifCount}</span>}
        <IoIosNotificationsOutline />
      </div>
      <div className="logOut" onClick={logOut}>
        <RiLogoutBoxLine />
      </div>
      <div
        onClick={closeNotif}
        className={notif ? "notifModal active" : "notifModal"}
      ></div>
      <div className="cards">
        {notifData.map((val) => (
          <div className="card_not">
            <div className="name">
              #{i++} - {val.first_name} buyurtma berdi
            </div>
            <div className="komp">Kompyuterlar:</div>
            <div className="komp_id">{val.computers_id}</div>
            <div className="bin" onClick={() => deleteNot(val.id)}>
              <RiDeleteBin6Line />
            </div>
          </div>
        ))}
      </div>
      {/* <audio src={notiiiif} autoPlay></audio> */}
      {notifCount > 0 ? (
        <audio autoPlay>
          <source src={notiiiif} type="audio/mpeg" />
        </audio>
      ) : (
        ""
      )}
    </div>
  );
}

export default Home;
