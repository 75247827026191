import React, { useEffect, useState } from "react";

const Timer = ({ end_time, tarif_narxi, start_time, isvip }) => {
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();
  const [second, setSecond] = useState();

  function countdownTimer() {
    const targetDate = new Date(end_time);
    const now = new Date();

    const remainingTime = targetDate - now;

    let hours = Math.floor(remainingTime / (1000 * 60 * 60));
    let minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    setHour(hours);
    setMinute(minutes);
    setSecond(seconds);
  }

  let totalMin = hour * 60 + minute;

  let min = tarif_narxi / 60;

  setInterval(countdownTimer, 1000);

  // ==========================================

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const updatedTimestamp1 = new Date(start_time).getTime() / 1000;
  const currentTimestamp1 = currentTime.getTime() / 1000;
  const timeDifference = currentTimestamp1 - updatedTimestamp1;

  const hours1 = Math.floor(timeDifference / 3600);
  const minutes1 = Math.floor((timeDifference % 3600) / 60);

  let totalMin1 = hours1 * 60 + minutes1;

  let min1 = tarif_narxi / 60;

  return (
    <>
      <td className="salom">
        <p>
          {isvip === 0
            ? Math.round(totalMin * min) + " UZS "
            : Math.round(totalMin1 * min1) + " UZS "}
        </p>
      </td>
      <td className="salom">
        <p>
          {Math.abs(
            hour < 10 && hour > 0 ? `0${hour}` : hour < 0 ? hour + 1 : hour
          )}
          :
          {Math.abs(
            minute < 10 && minute > 0
              ? `0${minute}`
              : minute < 0
              ? minute + 1
              : minute
          )}
          :
          {Math.abs(
            second < 10 && second > 0
              ? `0${second}`
              : second < 0
              ? second + 1
              : second
          )}
        </p>
      </td>
    </>
  );
};

export default Timer;
